import React from 'react';
import KontaktButton from '../KontaktButton';

const KontaktCta = (props) => {

    return (
        <div className="kontakt-cta mb-4">
            {props.children && (
                <div style={{ "flex":"none" }}>
                    {props.children}
                </div>
            )}
            <div style={{ "flex":"none" }}>
                <KontaktButton />
            </div>
        </div>
    );
};

export default KontaktCta;