import React from "react";

const RefEntry = ({ name, desc, url }) => {

  return (
    <li className="ref-entry">
      <div className="ref-name">{name}</div>
      <div className="ref-logo">
        <img
          src={url}
          width="150"
          height="50"
          title=""
          alt=""
        />
      </div>
      <div className="ref-desc">{desc}</div>
    </li>
  )
}


const Referenzen = () => {
  return (
    <div id="text">
      <p className="absatz">
        Folgende Unternehmen haben unsere Leistungen schon <br />
        in Anspruch genommen (Auswahl):
      </p>

      <ul>
        <RefEntry 
          name="Lebensart im Norden" 
          url="grafik/referenzen/ref_logo_lebensart.jpg" 
          desc="Lieferung von 4 DTP-Arbeitsplätzen incl. Software und Schulungen." 
        />
        
        <RefEntry 
          name="Ing.-Büro für Arbeits- und Gesundheitsschutz Seeleib (Kiel)" 
          url="grafik/referenzen/ref_logo_seeleib.jpg"
          desc="Lieferung diverser Mac-Hardware, Systembetreuung, Schulung"
        />
        
        <RefEntry 
          name="Fotograf Renard (Kiel)" 
          url="grafik/referenzen/ref_logo_renard.jpg"
          desc="Lieferung diverser Mac Hardware, Systembetreuung"
        />

        <RefEntry 
          name="Bundesvereinigung für Gesundheit (Bonn)" 
          url="grafik/referenzen/ref_logo_bvfg.jpg"
          desc="Lieferung diverser Mac-Hardware"
        />

        <RefEntry 
          name="Matthias Koch / Dipl. Grafik-Designer" 
          url="grafik/referenzen/ref_logo_exklusiv.jpg"
          desc="Lieferung von DTP-Systemen"
        />
        
        <RefEntry 
          name="Ursel M. Freels - Dipl. Grafik-Designerin (Kiel)" 
          url="grafik/referenzen/ref_logo_freels.jpg"
          desc="Lieferung eines DTP-Arbeitsplatzes"
        />
        
        <RefEntry 
          name="Fotogalerie Ingwersen (Wyk auf Föhr)" 
          url="grafik/referenzen/ref_logo_ingwersen.jpg"
          desc="Lieferung diverser Mac-Hardware, Systembetreuung"
        />

        <RefEntry 
          name="Agentur Lenz (Husum)" 
          url="grafik/referenzen/ref_logo_lenz.jpg"
          desc="Lieferung eines Mac-DTP-Systems und Hardware, Systembetreuung"
        />
        
        <RefEntry 
          name="Architekten- und Ingenieurkammer Kiel" 
          url="grafik/referenzen/ref_logo_aish.gif"
          desc="Lieferung eines Mac-DTP-Systems, Systembetreuung, Schulung"
        />
        
        <RefEntry 
          name="Staatliche Schule Gesundheitspflege Hamburg" 
          url="grafik/referenzen/ref_logo_ssgw.jpg"
          desc="Komplettausrüstung eines Klassenraumes mit 14 Macs, 1 Server, Peripherie und Software. Systembetreuung, Schulung"
        />
      </ul>
    </div>
  );
};

export default Referenzen;
