import React from 'react';

const Home = () => {
    return (
        <article className="max-line-width">
            <header>
                <h1>Über PSU</h1>
            </header>

            <h2>Wir sind</h2>
            <p>... ein Dienstleistungsunternehmen aus dem Norden von Kiel im Kreis Rendsburg/Eckernförde, dass sich dem Apple Macintosh verschrieben hat. Unsere Kernkompetenzen liegen darin, ihnen den besten Computer ihres Lebens zu verschaffen und dafür zu sorgen, dass er es auch bleibt.</p>
        
            <h2>Persönlicher Service ist unsere Stärke</h2>
            <p>Vom Kauf ihres Apple Macintoshs bis zur Reparatur steht Ihnen PSU mit Rat und Tat zur Seite, wenn Sie es wünschen. PSU betreibt keinen anonymen Onlinehandel und hat auch kein Ladengeschäft. Bei uns können Sie eine Auswahl aktueller Macs in einer realen Büroumgebung sehen und auch selber ausprobieren.</p>

            <h2>Ihr Partner – mit Erfahrung und Verlässlichkeit</h2>
            <p>Dies sind die Grundsätze, auf die wir die Partnerschaft mit unseren Kunden aufbauen. Wir sind unabhängig von größeren Unternehmen und können Sie deshalb jederzeit objektiv und fair beraten. Betreuung, Schulung und Service werden so individuell wie möglich gehalten.</p>
        </article>
    )
}

export default Home;