import React from 'react';
import { Link } from "react-router-dom";

const Mediendesign = () => {
    return (
        <>
            <p>Durch unsere langjährige Erfahrung mit dem Mac im Bereich des Desktop Publishing können wir Ihnen auch qualifizierte Angebote im Bereich der Mediengestaltung machen.</p>
            <h3>Gestaltung und Werbegrafik</h3>
            <p>Broschüren, Flyer, Faltblätter<br />
            Digitale Bildbearbeitung<br />
            Anzeigen<br />
            Individuelle Ereigniskarten (Geburtstage, Hochzeiten, usw.)</p>
            <h3>Identity</h3>
            <p>Visitenkarten<br />
            Geschäftspapiere<br />
            Corporate Design<br />
            Dokumentationen<br />
            Präsentationen</p>
            <h3>Video</h3>
            <p>Video - Digitalisierung</p>
            Und noch vieles mehr! <Link>Fragen sie uns einfach!</Link>
        </>
    );
}

export default Mediendesign;