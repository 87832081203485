import React from 'react';
import HomePage from './pages/Home';
import AboutPage from './pages/About';
import LeistungenPage from './pages/Leistungen';
import KontaktPage from './pages/Kontakt';
import AGBPage from './pages/AGB';
import DatenschutzPage from './pages/Datenschutz';
import ImpressumPage from './pages/Impressum';
import NotFoundPage from './pages/NotFound';
import FooterNav from './components/FooterNav';
import SiteHeader from './components/SiteHeader';
import { Route, NavLink, Switch } from "react-router-dom";
import { throttle } from 'throttle-debounce';

import "./styles/main.scss";

export default class App extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            width: document.documentElement.clientWidth,
            height: document.documentElement.clientHeight,
            navToggled: false
        }

        let setViewport = 
            throttle(100, 
            
            () => {
                this.setState({
                    width: document.documentElement.clientWidth,
                    height: document.documentElement.clientHeight
                })
            }
        );

        this.setViewport = setViewport.bind(this);
        this.toggleNavDrawer = this.toggleNavDrawer.bind(this);
        this.closeNavDrawer = this.closeNavDrawer.bind(this);

        window.addEventListener('resize', this.setViewport)
    }

    toggleNavDrawer(){
        this.setState({navToggled: !this.state.navToggled})
    }

    closeNavDrawer(){
        this.setState({navToggled: false})
    }

    render() {

        const collapseMenu = this.state.width < 700;

        return (
            <>
            <main>  
                <SiteHeader>
                    {collapseMenu && (
                        <div className="mainnav-toggle">
                            <button className="mainnav-toggle__button" onClick={this.toggleNavDrawer}>
                                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXLink="http://www.w3.org/1999/xlink" height="32px" version="1.1" viewBox="0 0 32 32" width="32px"><path fill="#fff" d="M4,10h24c1.104,0,2-0.896,2-2s-0.896-2-2-2H4C2.896,6,2,6.896,2,8S2.896,10,4,10z M28,14H4c-1.104,0-2,0.896-2,2  s0.896,2,2,2h24c1.104,0,2-0.896,2-2S29.104,14,28,14z M28,22H4c-1.104,0-2,0.896-2,2s0.896,2,2,2h24c1.104,0,2-0.896,2-2  S29.104,22,28,22z"/></svg>
                                <span>Menu</span>
                            </button>
                        </div>
                    )}
                    {!collapseMenu && (
                        <nav className="mainnav">
                            <ul>
                                <li><NavLink exact to="/" className="mainnav__link" activeClassName="is-active" id="startlink">Start</NavLink></li>
                                <li><NavLink to="/ueber-psu" className="mainnav__link" activeClassName="is-active" id="ueberpsulink">Über uns</NavLink></li>
                                <li><NavLink to="/leistungen" className="mainnav__link" activeClassName="is-active" id="leistungenlink">Leistungen</NavLink></li>
                                <li><NavLink to="/kontakt" className="mainnav__link" activeClassName="is-active" id="kontaktlink">Kontakt</NavLink></li>
                            </ul>
                        </nav>
                    )}
                </SiteHeader>

                <div className="content">
                    {/* 
                        Reset scroll on transition
                        https://stackoverflow.com/questions/36904185/react-router-scroll-to-top-on-every-transition 
                    */}
                    <Switch>
                        <Route path="/" exact component={HomePage} />
                        <Route path="/ueber-psu" component={AboutPage} />
                        <Route path="/leistungen">
                            <LeistungenPage/>
                        </Route>
                        <Route path="/kontakt" component={KontaktPage} />
                        <Route path="/agb" component={AGBPage} />
                        <Route path="/datenschutz" component={DatenschutzPage} />
                        <Route path="/impressum" component={ImpressumPage} />
                        <NotFoundPage/>
                    </Switch>
                </div>
                
                <FooterNav />
            </main>
            {collapseMenu && (
                <div className={`mainnav-drawer ${this.state.navToggled ? 'is-toggled' : ''}`}>
                    <div className="mainnav-drawer__cover">
                        <button className="mainnav-drawer__close" onClick={this.closeNavDrawer}>Close</button>
                        <nav className="drawer-nav">
                            <ul>
                                <li><NavLink exact to="/" className="drawer-nav__link" activeClassName="is-active" id="startlink">Start</NavLink></li>
                                <li><NavLink to="/ueber-psu" className="drawer-nav__link" activeClassName="is-active" id="ueberpsulink">Über uns</NavLink></li>
                                <li><NavLink to="/leistungen" className="drawer-nav__link" activeClassName="is-active" id="leistungenlink">Leistungen</NavLink></li>
                                <li><NavLink to="/kontakt" className="drawer-nav__link" activeClassName="is-active" id="kontaktlink">Kontakt</NavLink></li>
                            </ul>
                        </nav>
                    </div>
                </div>
            )}
            </>
        )
    }
}