import React from 'react';

const Service = () => {
    return (
        <>
            <h3>Sicherer Umstieg vom PC auf den Mac</h3>
            <p>PSU hilft Ihnen beim Wechsel zum Apple Macintosh und erarbeitet mit Ihnen Schritt für Schritt die Besonderheiten und Möglichkeiten ihres neuen Computers.</p>
            <h3>Reparatur</h3>
            <p>Bei Hardwaredefekten übernimmt nicht PSU die Reparatur, sondern unser zertifizierter Apple-Service-Partner mStore Kiel. Natürlich holen wir, wenn Sie es wünschen, ihren Computer bei Ihnen ab.</p>
            <h3>Problembehebung</h3>
            <p>Sollten nach Erhalt Ihres Apple Computers Fragen oder kleine Bedienungsprobleme auftreten, bieten wir Ihnen kostenlose Problemlösung per Telefon. Wir bieten Ihnen auch einen Vor-Ort-Service für den Fall, dass Ihre Probleme nicht telefonisch lösbar sind.</p>
        </>
    );
}

export default Service;