import React from "react";
import {
  NavLink,
  Route,
  Switch,
  useRouteMatch,
  useParams,
} from "react-router-dom";
import LeistungenGrid from "../../components/LeistungenGrid";

import LeistungHardware from "./subpages/Hardware";
import LeistungService from "./subpages/Service";
import LeistungMediendesign from "./subpages/Mediendesign";
import LeistungSchulungen from "./subpages/Schulungen";
import LeistungReferenzen from "./subpages/Referenzen";

const leistungEnum = {
  hardware: { component: LeistungHardware, title: "Hardware" },
  service: { component: LeistungService, title: "Service" },
  mediendesign: { component: LeistungMediendesign, title: "Mediendesign" },
  schulungen: { component: LeistungSchulungen, title: "Schulungen" },
  referenzen: { component: LeistungReferenzen, title: "Referenzen" },
};

const Topic = () => {
  const { leistung } = useParams();

  const { component: LeistungContent, title } = leistungEnum[leistung];

  return (
    <article className="max-line-width">
      <nav className="subnav">
        <ul>
          <li>
            <NavLink
              exact
              className="subnav__link"
              activeClassName="is-active"
              to={`/leistungen/hardware`}
            >
              Hardware
            </NavLink>
          </li>
          <li>
            <NavLink
              exact
              className="subnav__link"
              activeClassName="is-active"
              to={`/leistungen/service`}
            >
              Service
            </NavLink>
          </li>
          <li>
            <NavLink
              exact
              className="subnav__link"
              activeClassName="is-active"
              to={`/leistungen/mediendesign`}
            >
              Mediendesign
            </NavLink>
          </li>
          <li>
            <NavLink
              exact
              className="subnav__link"
              activeClassName="is-active"
              to={`/leistungen/schulungen`}
            >
              Schulungen
            </NavLink>
          </li>
          {/* <li>
            <NavLink
              exact
              className="subnav__link"
              activeClassName="is-active"
              to={`/leistungen/referenzen`}
            >
              Referenzen
            </NavLink>
          </li> */}
        </ul>
      </nav>
      <header>
        <h1>{title}</h1>
      </header>
      <LeistungContent />
    </article>
  );
};

const LeistungenRoot = () => {
  return (
    <article>
      <header>
        <h1>Unsere Leistungen</h1>
      </header>
      <LeistungenGrid />
    </article>
  );
};

const Leistungen = () => {
  const { path } = useRouteMatch();

  return (
      <Switch>
        <Route exact path={path} component={LeistungenRoot} />
        <Route path={`${path}/:leistung`} component={Topic} />
      </Switch>
  );
};

export default Leistungen;
