import React from 'react';
import KontaktCta from '../../components/KontaktCta';

const Home = () => {
    return (
        <article className="max-line-width">
            <header>
                <h1>PSU Kontaktieren</h1>
            </header>

            <p>Sie haben eine Frage zu einem unserer Angebote? Oder möchten Sie sich schon ein Angebot machen lassen? Kein Problem. Schreiben Sie uns. (Hinweis: Als Einzelunternehmen beschäftigen wir keinen separaten Datenschutzbeauftragten.)</p>
            
            <KontaktCta />

        </article>
    )
}

export default Home;


