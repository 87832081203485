import React from 'react';
import { Email } from "react-obfuscate-email"

const KontaktButton = () => {

    return (
        <Email className="kontakt-btn" subject="Anfrage" email="info@psu-ulrich.de">PSU kontaktieren</Email>
    );
};

export default KontaktButton;