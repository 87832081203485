import React from 'react';
import { Link } from "react-router-dom";

const Schulungen = () => {
    return (
        <>
            <h3>Lernen sie Ihren Computer kennen</h3>
            <p>PSU bietet ihnen zu vielen Themen rund um Ihren Mac Schulungen an. Beherrschen sie Ihr Betriebssystem oder nutzen sie Ihre Arbeitsprogramme effizienter. Wir zeigen ihnen, wie.</p>
            <h3>Wie und Wo?</h3>
            <p>Schulungen können bei Ihnen oder bei PSU inhouse (bis zu 4 Personen) stattfinden. Auf jeden Fall wird direkt am Mac gearbeitet.</p>
            <h3>Mögliche Themen:</h3>
            <ul>
                <li>Alles, was auf Ihrem Mac installiert ist, insbesondere das Betriebssystem Mac OS X</li>
                <li>Arbeitsprogramme: Office, iMovie, Fotos, Keynote, Pages, Numbers</li>
                <li>Elektronische Bildbearbeitung</li>
            </ul>
            <p>Preise je nach Inhalt und <Link>auf Anfrage</Link>.</p>
        </>
    );
}

export default Schulungen;