import React from 'react';
import LeistungenGrid from '../../components/LeistungenGrid';
import KontaktCta from '../../components/KontaktCta';


const Home = () => {
    return (
        <>
            <header className="max-line-width">
                <h1>PSU. Ihr Dienstleister für alles rund um den Apple Macintosh</h1>
            </header>
            
            <div className="grid-half">
                <p className="mb-2">
                    Haben Sie Appetit auf Äpfel? Wir auch, besonders auf die Sorte &quot;Macintosh&quot;. Ob Sie leidenschaftliche: Nutzer:in sind oder bloss einmal hereinschnuppern wollen - in PSU finden Sie einen verlässlichen Partner.
                </p>

                <div style={{ justifySelf: "center" }}>
                    <KontaktCta>
                        <div>Schreiben sie uns.<br />Wir beraten sie gern!</div>
                    </KontaktCta>
                </div>
            </div>

            <LeistungenGrid />
        </>
    )
}

export default Home;