import React from 'react';
import { Link } from "react-router-dom";

const FooterNav = () => {
    return (
        <footer className="footer">
            {/* <Link to="/agb">AGB</Link> */}
            <Link to="/datenschutz">Datenschutz</Link>
            <Link to="/impressum">Impressum</Link>
        </footer>
    )
}

export default FooterNav;