import React from 'react';
import PSULogo from '../PSULogo';
import titelStart from '../../img/titel_start.jpg';
import titelSchulungen from '../../img/titel_schulungen.jpg';
import titelHardware from '../../img/titel_hardware.jpg';
import titelDesign from '../../img/titel_mdesign.jpg';
import titelRefs from '../../img/titel_referenzen.jpg';
import titelService from '../../img/titel_service.jpg';
import titelAbout from '../../img/titel_ueberuns.jpg';
import titelDefault from '../../img/titel_default.jpg';
import { useLocation } from "react-router-dom";

import { useHistory } from "react-router-dom";


const SiteHeader = (props) => {
    function handleClick() {
        history.push("/");
    }
    const location = useLocation();

    const parts = location.pathname.split('/')
    const locationKey = parts[parts.length - 1];
    const history = useHistory();
    let img = null;

    switch(locationKey) {
        case 'ueber-psu':
            img = titelAbout
        break;
        case 'kontakt':
            img = titelDefault
        break;
        case 'referenzen':
            img = titelRefs
        break;
        case 'hardware':
            img = titelHardware
        break;
        case 'mediendesign':
            img = titelDesign   
        break;
        case 'service':
            img = titelService
        break;
        case 'schulungen':
            img = titelSchulungen;    
        break;
        default: 
            img = titelStart;
    }

    return (
        <header className="siteheader" style={{ backgroundImage: `url('${img}')` }}>
            <a className="siteheader__logo" style={{"cursor": "pointer"}} onClick={handleClick}><PSULogo /></a>
            {/* eslint-disable-next-line react/prop-types */}
            {props.children}
        </header>
    )
}

export default SiteHeader;