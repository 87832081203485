import React from "react";

const Hardware = () => {
    console.log('render hardware');

  return (
    <>
      <h3>Ihr neuer Apple Macintosh</h3>
      <p className="">
        Sie möchten sich einen Apple Macintosh kaufen, wissen aber nicht wie und
        woher? Sie finden sich nicht innerhalb der riesigen Anzahl der Angebote
        zurecht? PSU kann Ihnen weiterhelfen.
      </p>
      <h3>Wir analysieren</h3>
      <p>
        <ul className="textliste">
          <li>Was sind Ihre Gewohnheiten?</li>
          <li>Wo setzen Sie Ihren Mac ein?</li>
          <li>Wie werden Sie Ihren Mac in Zukunft einsetzen?</li>
        </ul>
      </p>
      <h3>Wir konfigurieren</h3>
      <p>
        Nach Ihren Anforderungen stellen wir Ihnen die passende Hardware
        zusammen. Das System und alle von Ihnen benötigten Programme werden
        Installiert.
      </p>
      <h3>Wir installieren</h3>
      <p>
        Aufbau aller Komponenten an Ihrem Arbeitsplatz / Büro. Ihr Macintosh ist
        dann sofort startklar. Garantiert!
      </p>
    </>
  );
};

export default Hardware;
