import React from 'react';
import { Link } from "react-router-dom";

import titelSchulungen from '../../img/titel_schulungen.jpg';
import titelHardware from '../../img/titel_hardware.jpg';
import titelService from '../../img/titel_service.jpg';
import titelDesign from '../../img/titel_mdesign.jpg';

const links = [
    {
        label: 'Service',
        link: '/leistungen/service',
        image: titelService,
    },
    {
        label: 'Hardware',
        link: '/leistungen/hardware',
        image: titelHardware,
    },
    {
        label: 'Schulungen',
        link: '/leistungen/schulungen',
        image: titelSchulungen,
    },
    {
        label: 'Mediendesign',
        link: '/leistungen/mediendesign',
        image: titelDesign,
    }
]

const LeistungenGrid = () => {
    return (
        <div className="leistungen-grid">
        {links.map((item, i) => {
            return (
                <Link key={i} to={item.link} className="leistung-link" style={{ backgroundImage: 'url(' + item.image + ')' }}>
                    <div className="leistung-link__label"><span>{item.label}</span></div>
                </Link>
            )
        })}
    </div>
    );
};

export default LeistungenGrid;